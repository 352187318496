import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  fetchUser,
  fetchCompanyDetails,
} from "../../../../redux/actions/companies.action";
import { fetchOrganizationCompanyList } from "../../../../redux/actions/organization.action";
import { fetchCompanies } from "../../../../redux/actions/companies.action";
import { fetchNotificationCodes } from "../../../../redux/actions/notification.action";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _, { filter } from "lodash";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MaterialIcon from "material-icons-react";
import Select from "react-select";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import axios from "../../../../axios";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "../../Companies/validations";
import Helper from "../../../../helper";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

let companyId = "";
let organizationId = localStorage.getItem("organizationId") || null;

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class CompanyList extends React.Component {
  state = {
    users: null,
    organizationCompanyList: null,
    list: true,
    user: {},
    saving: false,
    superAdmin: false,
    phoneNo: "",
    columns: [
      {
        text: "Company Id",
        dataField: "companyId",
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("/v1/companies/" + row.companyId);
            localStorage.setItem("companyId_org", row.companyId);
          },
        },
      },
      {
        text: "Company Name",
        dataField: "customName",
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("/v1/companies/" + row.companyId);
            localStorage.setItem("companyId_org", row.companyId);
          },
        },
      },
      {
        text: "Delete",
        dataField: "companyId",
        formatter: this.actionFormatter.bind(this),
        hidden: false,
      },
    ],
    company: {
      cmPOC: null,
      csPOC: null,
      region: null,
      timezone: "",
      notificationToBeChecked: "false",
      active: "true",
      Layout: null,
      companyName: null,
      companyId: null,
      customName: null,
      headQuarters: null,
      aboutCompany: null,
      dashboard_link: "https://nanoprecisedataservices.com/",
      energyCostFactor: 0.19,
      hyperlink_link: "https://nanoprecisedataservices.com/",
      defaultLang: {
        key: "defaultLang",
        langType: "eng",
        label: "English",
      },
      secondLang: {
        key: "secondLang",
        langType: "eng",
        label: "English",
      },
      config: {
        sensorHealthDashboard: false,
        exportDataToPtc: false,
        healthSummaryDashboard: false,
        soundHPF: true,
        S3ForStaticData: true,
        overallrms: false,
        demo: false,
        hidden: false,
        unit: "metric",
        dataExport: {
          active: false,
          url: "https://cloud.stg.az.relayr.io",
          key: "",
        },
        maintenanceLog: false,
        thresholdSharing: {
          active: false,
          keyList: [],
        },
        dailyConsolidatedAlarm: {
          active: false,
          directMessageCodes: {},
          numberOfBatchNotificationPerDay: 1,
          includeDirectNotification: true,
          smsNotification: true,
        },
        idleTimeConfig: {
          type: "lastRunning",
          rangeColor: [
            {
              idleDuration: "0-30",
              color: "#7ada2c",
            },
            {
              idleDuration: "31-60",
              color: "#f0c82a",
            },
            {
              idleDuration: "61-90",
              color: "#f57c00",
            },
            {
              idleDuration: ">90",
              color: "#fa0011",
            },
            {
              idleDuration: "No Data",
              color: "#BDBDBD",
            },
          ],
        },
        notificationConfig: {
          batteryNotification: false,
          batteryNotificationThreshold: 2.5,
        },
        s3Export: {
          active: false,
          bucket: "",
        },
      },
      sensorStatsFrequencyInHours: 96,
      externalId: null,
    },
    layoutType: [
      {
        key: 0,
        label: "plant-equipment-component",
        value: "plantEquipmentComponent",
      },
      {
        key: 1,
        label: "plant-machine-equipment-component",
        value: "plantMachineEquipmentComponent",
      },
    ],
    directMessageOptions: [],
  };

  actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <MaterialIcon
        onClick={this.handleDelete.bind(this, row)}
        icon="delete"
        color="red"
      ></MaterialIcon>
    );
  }

  async componentDidMount() {
    this.props.fetchCompanies();
    let directMessageOptions = await this.props.fetchNotificationCodes(
      "customer"
    );
    // await this.getTimezones();
    // await this.getDemoUsers();

    companyId = this.props.match.params.companyId;
    let organizationId = localStorage.getItem("organizationId");
    this.setState({ organizationId: parseInt(organizationId) });
    this.props.fetchOrganizationCompanyList(organizationId);
    let api = await axios.organizationConfig_details(organizationId);
    let configData = api && api.data && api.data.data;
    this.setState({
      cmPOC: configData.cmPOC,
      csPOC: configData.csPOC,
      country: configData.country,
      directMessageOptions: directMessageOptions,
    });

    // this.props.fetchCompanyDetails(companyId);
  }

  handleChange = (input) => (e) => {
    let user = { ...this.state.user };
    let company = { ...this.state.company };

    user[input] = e.target.value;
    company[input] = e.target.value;
    this.setState({ user, company: company });
  };
  handleSelectChange(e) {
    // console.log(e.value, "logdata");
    let { company } = this.state;

    if (e.value === "plantEquipmentComponent") {
      company.Layout = 0;
    } else if (e.value === "plantMachineEquipmentComponent") {
      company.Layout = 1;
    } else {
      company.Layout = null;
    }
    this.setState({ company });
  }
  handleDemoCompanies = async (event) => {
    let { demoOpen, company } = this.state;
    demoOpen = event.target.checked;
    company.config.demo = event.target.checked;
    this.setState({ demoOpen, company });
  };
  handleHiddenFeature = async (event) => {
    let { hiddenOpen, company } = this.state;
    hiddenOpen = event.target.checked;
    company.config.hidden = event.target.checked;
    this.setState({ hiddenOpen, company });
  };

  async handleSave() {
    this.setState({ saving: true });
    // let user = { ...this.state.user };
    // user.companyId = companyId;
    // user.userGroupId = 2000;
    // if (this.state.superAdmin) {
    //   user.superAdminInfo = {
    //     userName: user.userName,
    //     fullName: user.fullName,
    //     emailId: user.email,
    //     phoneNo: this.state.phoneNo,
    //     companyName: this.state.company.companyName,
    //     projectCode: this.state.company.companyId,
    //     totalNumberOfUsersAllowed: 100.0,
    //   };
    // }
    // //Validating
    // let testGroup = { ...user };
    // let err = await validation.validate(testGroup);
    // if (err) {
    //   toast.warn("Please enter valid input");
    //   this.setState({ saving: false });
    //   return;
    // }

    // let endpoint = "/users";
    // let api = await axios.postData(endpoint, user);
    // this.setState({ saving: false });
    // if (api.status == 200) {
    //   toast.success("User saved successfully");
    //   localStorage.removeItem("companyId");
    //   this.setState({ list: true });
    //   this.props.fetchUser(companyId);
    // } else if (api.status == 400) {
    //   toast.warn(api.data.message);
    // } else if (api.status === 500) {
    //   toast.error("Something went wrong. Please try again.");
    // }

    //Validating

    // let err = await validation.validate(this.state.company);

    if (
      !this.state.company.companyName ||
      this.state.company.companyName === null
    ) {
      toast.warn("Company Name is a required field");
      this.setState({ saving: false });
      return;
    }
    if (this.state.company.companyId === null) {
      toast.warn("Company code is a required field");
      this.setState({ saving: false });
      return;
    }
    if (this.state.company.companyId.length !== 4) {
      toast.warn("Company code must be 4 letter");
      this.setState({ saving: false });
      return;
    }
    let checkUpper =
      this.state.company &&
      this.state.company.companyId ==
        this.state.company.companyId.toUpperCase() &&
      this.state.company.companyId !=
        this.state.company.companyId.toLowerCase();
    if (!checkUpper) {
      toast.warn("Company id should be upper case");
      this.setState({ saving: false });
      return;
    }
    if (this.state.company.customName === null) {
      toast.warn("Custom name is a required field");
      this.setState({ saving: false });
      return;
    }
    if (this.state.company.headQuarters === null) {
      toast.warn("Head Quarter is a required field");
      this.setState({ saving: false });
      return;
    }
    if (this.state.company.Layout === null) {
      toast.warn("Please Select a Layout Type");
      this.setState({ saving: false });
      return;
    }
    if (this.state.company.aboutCompany === null) {
      toast.warn("About company is a required field");
      this.setState({ saving: false });
      return;
    }

    // if (err) {
    //   toast.warn(err);
    //   this.setState({ saving: false });
    //   return;
    // }

    let api = await axios.companyManage_create({
      companyName: this.state.company.companyName,
      companyId: this.state.company.companyId.toUpperCase(),
      aboutCompany: this.state.company.aboutCompany,
      customName: this.state.company.customName,
      Layout: this.state.company.Layout,
      active: true,
      hidden: this.state.company.config.hidden,
      headQuarters: this.state.company.headQuarters,
      externalId: this.state.company.externalId,
      organizationId: parseInt(this.state.organizationId),
    });

    this.setState({ saving: false });
    if (api && api.status == 200) {
      let selectedDMCodes = this.state.directMessageOptions;

      let saveConfig = {
        alarmHandlerConfig: {
          EmailPeakAcc: {
            consecutive: true,
            except: 25,
            frequency: 0,
            noOfPoints: 2,
            timeToConsider: 24,
          },
          EmailPeakDis: {
            consecutive: true,
            except: 25,
            frequency: 0,
            noOfPoints: 1,
            timeToConsider: 24,
          },
          EmailPeakVel: {
            consecutive: true,
            except: 25,
            frequency: 0,
            noOfPoints: 1,
            timeToConsider: 24,
          },
          EmailRmsAcc: {
            consecutive: true,
            except: 25,
            frequency: 0,
            noOfPoints: 2,
            timeToConsider: 24,
          },
          EmailRmsDis: {
            consecutive: true,
            except: 25,
            frequency: 0,
            noOfPoints: 1,
            timeToConsider: 24,
          },
          EmailRmsVel: {
            consecutive: true,
            except: 25,
            frequency: 0,
            noOfPoints: 2,
            timeToConsider: 24,
          },
          EmailSoundRms: {
            consecutive: true,
            except: 25,
            frequency: 0,
            noOfPoints: 16,
            timeToConsider: 24,
          },
          NanoAiAmplitude: {
            consecutive: true,
            except: 25,
            frequency: 168,
            noOfPoints: 2,
            timeToConsider: 24,
          },
          NanoAiPeakAcc: {
            consecutive: true,
            except: 25,
            frequency: 720,
            noOfPoints: 2,
            timeToConsider: 24,
          },
          NanoAiPeakDis: {
            consecutive: true,
            except: 25,
            frequency: 720,
            noOfPoints: 1,
            timeToConsider: 24,
          },
          NanoAiPeakVel: {
            consecutive: true,
            except: 25,
            frequency: 720,
            noOfPoints: 1,
            timeToConsider: 24,
          },
          NanoAiRmsAcc: {
            consecutive: true,
            except: 25,
            frequency: 720,
            noOfPoints: 2,
            timeToConsider: 24,
          },
          NanoAiRmsDis: {
            consecutive: true,
            except: 25,
            frequency: 720,
            noOfPoints: 1,
            timeToConsider: 24,
          },
          NanoAiRmsVel: {
            consecutive: true,
            except: 25,
            frequency: 720,
            noOfPoints: 2,
            timeToConsider: 24,
          },
          NanoAiSoundRms: {
            consecutive: true,
            except: 25,
            frequency: 720,
            noOfPoints: 16,
            timeToConsider: 24,
          },
          active: true,
        },
        cdpLateStageFaultConfig: {
          Healthy: {
            action: false,
            frequency: null,
            message: null,
            waitingTime: null,
          },
          NeedsAttention: {
            action: true,
            frequency: 168,
            message: "Schedule maintenance activity",
            waitingTime: 0,
          },
          NeedsImmediateAttention: {
            action: true,
            frequency: 168,
            message: "Repair immediately",
            waitingTime: 0,
          },
          NeedsMaintenanceReview: {
            action: true,
            frequency: 168,
            message: "Review maintenance plan and parts availability",
            waitingTime: 0,
          },
          active: true,
        },
        companyId: this.state.company.companyId,
        timezone: "Asia/Kolkata",
        notificationToBeChecked: false,
        dashboard_link: "https://nanoprecisedataservices.com/",
        hyperlink_link: "https://nanoprecisedataservices.com/",
        defaultLang: {
          key: "defaultLang",
          langType: "eng",
          label: "English",
        },
        secondLang: {
          key: "secondLang",
          langType: "eng",
          label: "English",
        },
        sensorHealthDashboard: true,
        exportDataToPtc: false,
        healthSummaryDashboard: false,
        energyEfficiency: false,
        soundHPF: true,
        S3ForStaticData: true,
        overallrms: false,
        demo: this.state.company.config.demo,
        unit: "metric",
        dataExport: {
          active: false,
          url: "https://cloud.stg.az.relayr.io",
          key: "",
        },
        maintenanceLog: true,
        thresholdSharing: {
          active: false,
          keyList: [],
        },
        dailyConsolidatedAlarm: {
          active: true,
          directMessageCodes:
            selectedDMCodes && selectedDMCodes.length
              ? Helper.createDirectMessageJSON(selectedDMCodes)
              : {},
          numberOfBatchNotificationPerDay: 1,
          includeDirectNotification: true,
          smsNotification: true,
          startTime:{
            alarmStartTime: moment().utc().format('hh:mm'),
            alarmStartTimeString: moment().utc().format()
          }
        },
        idleTimeConfig: {
          type: "lastRunning",
          rangeColor: [
            {
              idleDuration: "0-30",
              color: "#7ada2c",
            },
            {
              idleDuration: "31-60",
              color: "#f0c82a",
            },
            {
              idleDuration: "61-90",
              color: "#f57c00",
            },
            {
              idleDuration: ">90",
              color: "#fa0011",
            },
            {
              idleDuration: "No Data",
              color: "#BDBDBD",
            },
          ],
        },
        notificationConfig: {
          batteryNotification: false,
          batteryNotificationThreshold: 2.5,
        },
        s3Export: {
          active: false,
          bucket: "",
        },
        sensorStatsFrequencyInHours: 96,
        vib_rms_unit: "vel",
        vib_peak_unit: "acc",
        vib_spectrum_domain: "time",
        vib_spectrum_unit: "vel",
        vib_waterfall_domain: "time",
        vib_waterfall_unit: "vel",
        vib_waveform_domain: "time",
        vib_waveform_unit: "acc",
        vibSpecCpmRange: "55000",
        vibSpecDenoisedRange: "1000",
        vibSpecDomainValue: "cycle",
        vibSpecEnvFreqRange: "1000",
        vibSpecEnvOrdRange: "100",
        vibSpecFreqDomainRange: "1000",
        vibSpecOrderDomainRange: "100",
        vibSpecRange: "80000",
        temperature: "C",
        intenseNanoAIMeasurement: true,
        dynamicDashboardetails: { isDynamicDashboardDetails: false },
        region: this.state.region,
        cmPOC: this.state.cmPOC,
        csPOC: this.state.csPOC,
        country: this.state.country,
        lastRunningDiffLastCheckedTime: 1661799908,
        navigationOptionVisible: true,
        menuOptionVisible: true,
      };

      let api2 = await axios.companyConfig_all(saveConfig);
      if (api.status === 200 && this.state.csvfile) {
        let endpoint = "/users";
        this.setState({ isUpload: true });
        let api3 = await axios.postData(endpoint, this.state.usersList);

        toast.success("File uploaded Successfully");
        this.handleDemoSuccess();
      } else {
        toast.warn(api.data.message);
        let { company } = this.state;
        company.config.demo = false;
        this.setState({ company });
      }
      toast.success("Company saved successfully !");
      localStorage.removeItem("companyId");
      //Redirect to company details page
      // this.jump(
      //   "/v1/organizationdetails/" + this.state.organizationId + "/companyList",
      //   1000
      // );
      window.location.reload(true);
    } else if (api && api.status == 400) {
      if (api.data.data == "Existing record found with the same companyId") {
        let msg = `Company code ${this.state.company.companyId} already exist`;
        toast.warn(msg);
      } else if (api.data.data.includes("duplicate")) {
        let msg1 = `Company name ${this.state.company.companyName} already exist`;
        toast.warn(msg1);
      } else {
        toast.warn("Failed to save");
      }
    } else if (api && api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  async handleDelete(row) {
    let api = null;
    if (window.confirm("Do you really want to delete it?")) {
      api = await axios.companyManage_Delete(row.companyId);
      if (api && api.status === 200) {
        this.props.fetchOrganizationCompanyList(organizationId);
        toast.success("Company deleted successfully");
        window.location.reload(true);
        return;
      }
      if (api && api.status === 400) {
        toast.error("Failed to delete ");
      }
      if (api && api.status === 500) {
        toast.error("Something went wrong. Please try again");
      }
    }
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let users = null;
    let company_2 = null;
    let organizationCompanyList = null;
    let companies = null;

    if (nextProps.userData !== prevState.userData) {
      if (nextProps.userData) {
        users = nextProps.userData.data;
      }
    }
    //organizationCompanyList

    if (
      nextProps.organizationCompanyList !== prevState.organizationCompanyList
    ) {
      if (nextProps.organizationCompanyList) {
        organizationCompanyList = nextProps.organizationCompanyList.data;
      }
    }
    if (nextProps.companyDetailsData !== prevState.companyDetailsData) {
      if (nextProps.companyDetailsData) {
        company_2 = nextProps.companyDetailsData.data.company;
      }
    }
    if (nextProps.companyData !== prevState.companyData) {
      if (nextProps.companyData) {
        companies = nextProps.companyData.data;
      }
    }

    return {
      users: users,
      company_2: company_2,
      companies: companies,
      organizationCompanyList: organizationCompanyList,
    };
  }

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.list ? (
                <div
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : (
                <div
                  onClick={() => {
                    this.setState({ list: true });
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              )}
              {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.setState({ list: false });
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Company
                </Button>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Company List</h1>
            </Col>
          </Row>

          {/* Table */}

          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Company</h3>
                  </CardHeader>
                )}
                {this.state.organizationCompanyList && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          pagination={paginationFactory(options)}
                          keyField="_id"
                          data={this.state.organizationCompanyList}
                          columns={this.state.columns}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add company */}
                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Company Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              valid={false}
                              onChange={this.handleChange("companyName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Company Code (4 Char Upper Case){" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="email"
                              onChange={this.handleChange("companyId")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Custom Name <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              type="text"
                              onChange={this.handleChange("customName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Headquarter <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              onChange={this.handleChange("headQuarters")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Layout Type <span className="text-danger">*</span>
                            </label>

                            <Select
                              onChange={(e) => this.handleSelectChange(e)}
                              placeholder=""
                              options={this.state.layoutType}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              External ID{" "}
                              <Tooltip
                                title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                                placement="right-start"
                              >
                                <Info
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    fontSize: "30px",
                                  }}
                                />
                              </Tooltip>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              onChange={this.handleChange("externalId")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              About Company{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-about-company"
                              // placeholder="A few words about company ..."
                              rows="8"
                              type="textarea"
                              onChange={this.handleChange("aboutCompany")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <IOSSwitch
                                checked={this.state.company.config.demo}
                                onChange={this.handleDemoCompanies}
                                name="demo"
                              />
                            }
                            label={
                              this.state.company &&
                              this.state.company.config &&
                              this.state.company.config.demo === false
                                ? "Demo Dashboard"
                                : "Demo Dashboard Enabled"
                            }
                          />
                        </Col>
                        <Col lg="6">
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <IOSSwitch
                                checked={this.state.company.config.hidden}
                                onChange={this.handleHiddenFeature}
                                name="hidden"
                              />
                            }
                            label={
                              this.state.company &&
                              this.state.company.config &&
                              this.state.company.config.hidden === false
                                ? "Hidden disabled"
                                : "Hidden enabled"
                            }
                          />
                        </Col>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              //onClick={this.state.company.config.demo === true ? this.handleSaveDemo && this.handleSave : this.handleSave}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyDetailsData: _.isEmpty(state.companies.companyDetailsData)
    ? null
    : state.companies.companyDetailsData,
  organizationCompanyList: _.isEmpty(state.organization.organizationCompanyList)
    ? null
    : state.organization.organizationCompanyList,
  companyData: _.isEmpty(state.companies.companyData)
    ? null
    : state.companies.companyData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanyDetails: (companyId) =>
      dispatch(fetchCompanyDetails(companyId)),
    fetchUser: (companyId) => dispatch(fetchUser(companyId)),
    fetchOrganizationCompanyList: (organizationId) =>
      dispatch(fetchOrganizationCompanyList(organizationId)),
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchNotificationCodes: (roleId) =>
      dispatch(fetchNotificationCodes(roleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);
