/**
 * @typedef {string} QuesIDType
 *
 * @callback CallbackType - Callback function
 * @param {QuesIDType} quesID - Question ID
 * @returns {*} - Answer
 *
 * @typedef {Object} GetterSetterType - Object with getter and setter
 * @property {CallbackType} getFieldValue - Getter function
 * @property {CallbackType} setFieldsValue - Setter function
 *
 * @typedef {(GetterSetterType) => Object[]} RuleType - Rule function
 * @typedef {(GetterSetterType) => Object[]} HiddenType - Hidden function
 * @typedef {(Object) => *[]} ExtractorType - Extractor function
 *
 *
 * @typedef {Object} OptionType - Object with option
 * @property {RuleType} rules - Rules for validation
 * @property {HiddenType} [hidden] - Rules for hidden
 * @property {ExtractorType} [dataExtractor] - Function to extract data from option
 *
 * @typedef {Object} QuesType - Object with question
 * @property {QuesIDType} quesID - Question ID
 * @property {string} type - Question type
 * @property {string} ques - Question
 * @property {OptionType} option - Option
 *
 * @typedef {{[Key: string]: QuesType}} QuesListType {@link QuesType}
 */

/** @type {QuesListType} - {@link QuesListType} */
const QuesList = {
  RPM: {
    quesID: "qID_1",
    type: "Input",
    ques: "What is the RPM?",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [...isValidRPM("RPM")],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.speed.minRPM`, toNumber(ans), config);
      pathSetter(`config.speed.maxRPM`, null, config);
    },
    defaultAnswer: (config) => toString(config.speed.minRPM),
  },
  MinRPM: {
    quesID: "qID_2",
    type: "Input",
    ques: "What is the min RPM?",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        ...isValidRPM("min RPM"),
        {
          validator: (_, min) =>
            isMinSmallerThanMax(min, getFieldValue("qID_3")),
        },
      ],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.speed.minRPM`, toNumber(ans), config);
    },
    defaultAnswer: (config) => toString(config.speed.minRPM),
  },
  MaxRPM: {
    quesID: "qID_3",
    type: "Input",
    ques: "What is the max RPM?",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        ...isValidRPM("max RPM"),
        {
          validator: (_, max) =>
            isMaxGreaterThanMin(getFieldValue("qID_2"), max),
        },
      ],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.speed.maxRPM`, toNumber(ans), config);
    },
    defaultAnswer: (config) => toString(config.speed.maxRPM),
  },
  BearingType: {
    quesID: "qID_4",
    type: "Radio",
    ques: "What is the bearing type?",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        { required: true, message: "Please select bearing type!" },
      ],
      dataExtractor: ({ OptionBearingType }) => OptionBearingType || [],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.bearing.bearingType`, toNumber(ans), config);
    },
    defaultAnswer: (config) => toString(config.bearing.bearingType),
  },
  BearingDetails: {
    quesID: "qID_5",
    type: "BearingCustom",
    ques: "What are the Bearing Number/Make?",
    option: {
      dependencies: "qID_4",
      hidden: ({ getFieldValue, setFieldsValue }) =>
        getFieldValue("qID_4") === "1" ? true : false,
      rules: ({ getFieldValue, setFieldsValue }) => [
        {
          type: "array",
        },
        { required: true, message: "Please select Bearing details!" },
      ],
      dataExtractor: ({ OptionBearingList }) => OptionBearingList || [],
    },
    generateConfig: (ans, config) => {
      ans = ans.map((item) => {
        const [bearingNumber, bearingMake] = item.value.split("||");
        return { bearingNumber:bearingNumber ? bearingNumber.trim() :bearingNumber, bearingMake:bearingMake?bearingMake.trim():bearingMake};
      });
      pathSetter(`config.bearing.antiFriction`, ans, config);
    },
    defaultAnswer: (config) => {
      if (!Array.isArray(config.bearing.antiFriction)) return [];
      return config.bearing.antiFriction.map(
        (item) => {return{'label': `${item.bearingNumber} || ${item.bearingMake}`,  'value': `${item.bearingNumber} || ${item.bearingMake}`}}
      );
    },
  },
  GMFDetails: {
    quesID: "qID_6",
    type: "GMFCustom",
    ques: "What are the shaft details?...",
    option: {
      // rules: ({ getFieldValue, setFieldsValue }) => [
      //   isRequired("Shaft details"),
      // ],
      dataExtractor: ({ OptionBearingList }) => OptionBearingList || [],
    },
    generateConfig: (ans, config) => {
      ans = ans.map((item) => {
        const { bearingSelect, rpmInMin, noOfTeeth1, noOfTeeth2, rpmInMax } = item;
        const [shaftBearingNumber, shaftBearingMake] = bearingSelect && bearingSelect.value
          ? bearingSelect.value.split("||")
          : [null, null];
        return {
          rpmInMin: rpmInMin === "NA" ? null : parseInt(rpmInMin),
          rpmInMax: rpmInMax   === "NA" ? null : parseInt(rpmInMax),
          noOfTeeth1: noOfTeeth1 === "NA" ? null : parseInt(noOfTeeth1),
          noOfTeeth2: noOfTeeth2 === "NA" ? null : parseInt(noOfTeeth2),
          shaftBearingNumber:
            shaftBearingNumber === "NA" ? 'NA' : shaftBearingNumber.trim(),
          shaftBearingMake:
            shaftBearingNumber === "NA" ? "NA" : shaftBearingMake.trim(),
        };
      });
      pathSetter(`config`, ans, config);
    },
    defaultAnswer: (config) => {
      if (!Array.isArray(config)) return [];
      return config.map(
        ({
          shaftBearingNumber,
          shaftBearingMake,
          noOfTeeth1,
          noOfTeeth2,
          rpmInMin,
          rpmInMax,
          ...items
        }) => ({
          ...items,
          noOfTeeth1: noOfTeeth1 === null ? "NA" : noOfTeeth1,
          noOfTeeth2: noOfTeeth2 === null ? "NA" : noOfTeeth2,
          rpmInMin: rpmInMin === null ? "NA" : rpmInMin,
          rpmInMax: rpmInMax === null ? "NA" : rpmInMax,
          bearingSelect: shaftBearingNumber === null || shaftBearingNumber === 'NA'? {'label': 'NA',  'value': `NA`}:{'label': `${shaftBearingNumber} || ${shaftBearingMake}`,  'value': `${shaftBearingNumber} || ${shaftBearingMake}`}
        })
      );
    },
  },
  ElectricalLine: {
    quesID: "qID_7",
    type: "Radio",
    ques: "What is eletrical line frequency?",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        { required: true, message: "Please select Electrical Line Freq!" },
      ],
      dataExtractor: ({ ElectricalLineFreq }) => ElectricalLineFreq || [],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.stator.electricalLineFreq`, toNumber(ans), config);
    },
    defaultAnswer: (config) => toString(config.stator.electricalLineFreq),
  },
  RotorBars: {
    quesID: "qID_8",
    type: "Input",
    ques: "What is the number of rotor bars?\n(number or NA)",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        {
          required: true,
          pattern: "[^-\\s]",
          message: "Please input number of rotor bars!",
        },
      ],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.stator.numberOfBars`, isNaN(ans) ? 'NA' : toNumber(ans), config);
    },
    defaultAnswer: (config) => toString(config.stator.numberOfBars),
  },
  NoOfVanes: {
    quesID: "qID_9",
    type: "Input",
    ques: "What is the number of vanes?\n(number or NA)",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        {
          required: true,
          pattern: "[^-\\s]",
          message: "Please input number of number of vanes!",
        },
        // isValidInteger("number"),
      ],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.noVanes`, toNumber(ans), config);
    },
    defaultAnswer: (config) => toString(config.noVanes),
  },
  NoOfBlades: {
    quesID: "qID_10",
    type: "Input",
    ques: "What is the number of blades?\n(number or NA)",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        {
          required: true,
          pattern: "[^-\\s]",
          message: "Please input number of number of blades!",
        },
        // isValidInteger("number"),
      ],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.noBlades`, toNumber(ans), config);
    },
    defaultAnswer: (config) => toString(config.noBlades),
  },
  InstalledOn: {
    quesID: "qID_11",
    type: "Radio",
    ques: "Sensor is installed on",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        { required: true, message: "Please select case!" },
      ],
      dataExtractor: ({ OptionInstallType }) => OptionInstallType || [],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.mountLoc`, toNumber(ans), config);
    },
    defaultAnswer: (config) => config.mountLoc,
  },
  NoOfPoles: {
    quesID: "qID_12",
    type: "Input",
    ques: "What is the number of Poles?\n(number or NA)",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        {
          required: true,
          pattern: "[^-\\s]",
          message: "Please input number of number of poles!",
        },
        // isValidInteger("number"),
      ],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.numOfPoles`, isNaN(ans) ? 'NA' : toNumber(ans) , config);
    },
    defaultAnswer: (config) => toString(config.numOfPoles),
  },
  NoOfHorse: {
    quesID: "qID_13",
    type: "Input",
    ques: "What is the horsepower?\n(number or NA)",
    option: {
      rules: ({ getFieldValue, setFieldsValue }) => [
        {
          required: true,
          pattern: "[^-\\s]",
          message: "Please input number of horsepower!",
        },
        // isValidInteger("number"),
      ],
    },
    generateConfig: (ans, config) => {
      pathSetter(`config.horsepower`,  isNaN(ans) ? 'NA' : toNumber(ans) , config);
    },
    defaultAnswer: (config) => toString(config.horsepower),
  },
};

// ---

const pathSetter = (path, value, obj = {}) => {
  //generates final response object
  const pathArr = path.split(".");

  const [key, ...rest] = pathArr;

  if (!key) return Object.assign(obj, value);
  if (pathArr.length === 1) {
    console.log("valuevaluevaluevalue:", value);
    return Object.assign(obj, { ...obj, [key]: value });
  }

  return Object.assign(obj, {
    ...obj,
    [key]: pathSetter(rest.join("."), value, obj[key]),
  });
};

// ---

const toNumber = (value) => Number(value);
const toString = (value) => String(value);

// ---

const isRequired = (label) => ({
  require: true,
  message: `${label} is required`,
});

const isEntered = (label) => ({
  require: true,
  message: `Enter ${label}`,
});

const isSelected = (label) => ({
  require: true,
  message: `Select ${label}`,
});

const isGreaterThanZero = (label) => ({
  transform: toNumber,
  min: 1,
  type: "number",
  message: `${label} must be greater than 0`,
});

const isValidInteger = (label) => ({
  transform: toNumber,
  type: "integer",
  message: `Please input a valid ${label}`,
});

// ---

const isValidRPM = (label) => [
  isEntered(label),
  isValidInteger(label),
  isGreaterThanZero(label),
];

// ---

const isMaxGreaterThanMin = (min, max) => {
  if (toNumber(min) >= toNumber(max))
    return Promise.reject("Max RPM should be greater than Min RPM");

  return Promise.resolve();
};

const isMinSmallerThanMax = (min, max) => {
  if (toNumber(min) >= toNumber(max))
    return Promise.reject("Min RPM should be smaller than Max RPM");

  return Promise.resolve();
};

// ---

const getSpeedQues = (isConstantSpeed) => {
  if (isConstantSpeed) return [QuesList.RPM];

  return [QuesList.MinRPM, QuesList.MaxRPM];
};

const getBearingQues = (isAntiFriction) => {
  if (!isAntiFriction) return [QuesList.BearingType, QuesList.BearingDetails];

  return [QuesList.BearingType];
};

const getCommonQues = ({ isConstantSpeed, isAntiFriction }) => [
  ...getSpeedQues(isConstantSpeed),
  ...getBearingQues(isAntiFriction),
];

// ---

const QuesDict = {
  motor: {
    ac: (props) => [
      ...getCommonQues(props),
      QuesList.ElectricalLine,
      QuesList.RotorBars,
      QuesList.NoOfPoles,
      QuesList.NoOfHorse,
    ],
    dc: (props) => [...getCommonQues(props)],
    other: (props) => [...getCommonQues(props)],
  },
  turbine: {
    steam: (props) => [...getCommonQues(props)],
    gas: (props) => [...getCommonQues(props)],
    // Wind_turbine: (props) => [...getCommonQues(props)],
    other: (props) => [...getCommonQues(props)],
  },
  gearbox: {
    fixedAxis: (props) => [
      // ...getSpeedQues(props.isConstantSpeed),
      QuesList.GMFDetails,
    ],
    // Planetary_gearbox: (props) => [...getCommonQues(props)],
  },
  pump: {
    centrifugal: (props) => [...getCommonQues(props), QuesList.NoOfVanes],
    suckerRod: (props) => [],
    other: (props) => [...getCommonQues(props), QuesList.NoOfVanes],
  },
  fan: {
    centrifugal: (props) => [...getCommonQues(props), QuesList.NoOfBlades],
    other: (props) => [...getCommonQues(props), QuesList.NoOfBlades],
  },
  blower: {
    centrifugal: (props) => [...getCommonQues(props), QuesList.NoOfBlades],
    other: (props) => [...getCommonQues(props), QuesList.NoOfBlades],
  },
  compressor: {
    reciprocating: (props) => [
      ...getSpeedQues(props.isConstantSpeed),
      QuesList.InstalledOn,
    ],
    rotating: (props) => [
      ...getCommonQues(props), QuesList.NoOfVanes
    ],
    // RotoryScrew_compressor: (props) => [...getCommonQues(props)],
    other: (props) => [...getCommonQues(props)],
  },

  transformer: {
    power: (props) => [QuesList.ElectricalLine],
  },
  other: {
    other: (props) => [...getCommonQues(props)],
  },
  all: { all: () => Object.values(QuesList) },
};
export { QuesDict };
